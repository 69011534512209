import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useCheckoutPaymentContext } from '@wilm/shared-frontend/components/commercetools-ui/organisms/checkout/provider/payment';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import Skeleton from 'react-loading-skeleton';
import LearningDetails from 'components/commercetools-ui/organisms/checkout/components/steps/sections/learner-details';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useCybersourceContext } from 'providers/cybersource';
import { useCart, useAccount } from 'frontastic/hooks';
import AddressesPreview from './previews/addresses';
import PaymentPreview from './previews/payment';
import Addresses from './sections/addresses';
import Payment from './sections/payment';
import LoqateProvider from '../../../../../../context/loqate';
import CreateAddress from '../create-address';
import Step from '../step';
import type { TermsAndConditionsProps, TermsAndConditionsType } from '../terms-and-conditions';
import TermsAndConditions from '../terms-and-conditions';

interface Props {
    onFinalStepChange: (isFinalStep: boolean) => void;
    termsAndConditions: TermsAndConditionsType;
    handleTermsChange: TermsAndConditionsProps['handleTermsChange'];
    b2bCustomerInfoInvoiceSending: string;
    billingAddressMessage: string;
}

const Steps: React.FC<Props> = ({
    onFinalStepChange,
    termsAndConditions,
    handleTermsChange,
    b2bCustomerInfoInvoiceSending,
    billingAddressMessage
}) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { selectedType, sameAsBillingAddress, setSameAsBillingAddress } = useCheckoutPaymentContext();

    const { data: cartData, isLoading, hasShippingRequiredItem } = useCart();
    const { account } = useAccount();
    const { cybersourceSettings } = useCybersourceContext();
    const { enableCheckoutDelegatesStep } = useBrandSettingsContext();

    const router = useRouter();

    const searchParams = useSearchParams();

    const step = +(searchParams.get('step') ?? 0);
    const isB2B = account?.isB2B ? account?.isB2B : false;

    const { pathWithoutQuery } = usePath();

    const [active, setActive] = useState<number>(step);

    const goToNextStep = useCallback(() => {
        setActive(active + 1);
        router.push(`${pathWithoutQuery}?step=${active + 1}`, { scroll: false });
    }, [active, router, pathWithoutQuery]);

    useEffect(() => {
        setActive(step);
    }, [step]);

    useEffect(() => {
        // if active step is after than payments step selected payment method is different than 'invoice'
        if (step > 1 && selectedType !== PaymentMethodType.INVOICE) {
            // redirect to payment step
            router.push(`${pathWithoutQuery}?step=1`);
        }
    }, []);

    const onEdit = useCallback(
        (index: number) => {
            const query = index !== 0 ? `?step=${index}` : '';
            router.push(`${pathWithoutQuery}${query}`, { scroll: false });
        },
        [pathWithoutQuery, router]
    );

    const isLearnerStepEnabled = enableCheckoutDelegatesStep && isB2B;

    const steps = useMemo(() => {
        const learnerStep = {
            label: formatCartMessage({ id: 'learner.details', defaultMessage: 'Learner Details' }),
            Component: <LearningDetails goToNextStep={goToNextStep} />,
            Preview: <></>
        };
        const stepsArray = [
            {
                label: formatCartMessage({ id: 'addresses', defaultMessage: 'Addresses' }),
                Component: (
                    <Addresses
                        goToNextStep={goToNextStep}
                        sameAsBillingAddress={sameAsBillingAddress}
                        setSameAsBillingAddress={setSameAsBillingAddress}
                        isLearnerStepEnabled={isB2B && isLearnerStepEnabled}
                        b2bCustomerInfoInvoiceSending={b2bCustomerInfoInvoiceSending}
                        billingAddressMessage={billingAddressMessage}
                    />
                ),
                Preview: <AddressesPreview />,
                CTA:
                    account?.isB2B && !hasShippingRequiredItem ? <></> : <CreateAddress setSameAsBillingAddress={setSameAsBillingAddress} />
            },
            {
                label: formatCartMessage({ id: 'payment', defaultMessage: 'Payment' }),
                Component: <Payment goToNextStep={goToNextStep} />,
                Preview: <PaymentPreview />
            }
        ];

        if (account?.isB2B && isLearnerStepEnabled) {
            stepsArray.splice(1, 0, learnerStep);
        }

        return stepsArray;
    }, [formatCartMessage, goToNextStep, sameAsBillingAddress, setSameAsBillingAddress, account?.isB2B]);

    const isFinalStep = useMemo(() => active === steps.length, [active, steps.length]);

    useEffect(() => {
        onFinalStepChange(isFinalStep);
    }, [isFinalStep, onFinalStepChange]);

    useEffect(() => {
        // if active step is after than address step and cartData is loaded
        if (active > 0 && !isLoading) {
            const billingAddressExistInCart = Object.keys(cartData?.billingAddress ?? {}).length;
            const shippingAddressExistInCart = Object.keys(cartData?.shippingAddress ?? {}).length;

            // if shippingAddress or billingAddress is missing in cart
            if (!billingAddressExistInCart || !shippingAddressExistInCart) {
                router.push(`${pathWithoutQuery}?step=0`);
            }
        }
    }, [isLoading, active, cartData, router, pathWithoutQuery]);

    return (
        <LoqateProvider>
            <div className="mt-20 w-full px-16 md:px-24 lg:mt-0 lg:grow lg:px-0">
                {cybersourceSettings.flexFormLink ? (
                    <div className="flex flex-col gap-20 md:gap-40">
                        <Script src={cybersourceSettings.flexFormLink} strategy="lazyOnload" />
                        {steps.map(({ Component, Preview, label, CTA }, index) => (
                            <Step
                                key={label}
                                label={label}
                                number={index + 1}
                                isExpanded={index === active}
                                isCompleted={index < active}
                                onEdit={() => onEdit(index)}
                                Component={Component}
                                Preview={Preview}
                                CTA={CTA}
                                isFinalStep={isFinalStep}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="bg-white px-16 py-12 md:px-24 md:py-12 lg:px-20 lg:py-36 xl:px-40">
                        <Skeleton className="mt-16 h-30 w-full" />
                    </div>
                )}
                {isFinalStep && (
                    <div className="mt-30 hidden lg:block">
                        <TermsAndConditions termsAndConditions={termsAndConditions} handleTermsChange={handleTermsChange} />
                    </div>
                )}
            </div>
        </LoqateProvider>
    );
};

export default Steps;
