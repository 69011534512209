import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { CheckoutSettings } from '@wilm/shared-types/base/Settings';
import useSWR from 'swr';
import { sdk } from 'sdk';

const useCheckoutSettings = () => {
    const response = useSWR<SDKResponse<CheckoutSettings>>('action/project/getCheckoutSettings', () =>
        sdk.callAction<CheckoutSettings>({ actionName: 'project/getCheckoutSettings' })
    );

    const data = (response.data?.isError ? {} : response.data?.data)!;

    const invoiceEnabled = data?.invoiceEnabled ?? false;
    const creditCardEnabled = data?.creditCardEnabled ?? false;
    const instalmentsEnabled = data?.instalmentsEnabled ?? false;
    const instalmentRenewalMessage = data?.instalmentRenewalMessage ?? '';
    const unavailableInvoiceRenewalMessage = data?.unavailableInvoiceRenewalMessage ?? '';

    const checkoutSettings: CheckoutSettings & { isLoading: boolean } = {
        isLoading: response.isLoading,
        invoiceEnabled,
        instalmentsEnabled,
        creditCardEnabled,
        instalmentRenewalMessage,
        unavailableInvoiceRenewalMessage
    };

    return checkoutSettings;
};

export default useCheckoutSettings;
