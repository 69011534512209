import React, { useCallback, useEffect, useState } from 'react';
import useCheckoutSettings from '@wilm/shared-frontend/frontastic/hooks/useStudioCheckoutSettings';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import { ProductTypeKey } from '@wilm/shared-types/product/Product';
import type { FieldError } from '@wilm/shared-types/validation-rules/types';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import Radio from 'components/commercetools-ui/atoms/radio';
import usePoNumber from 'components/commercetools-ui/organisms/checkout/hooks/usePoNumber';
import { useCheckoutPaymentContext } from 'components/commercetools-ui/organisms/checkout/provider/payment';
import type { PaymentData } from 'components/commercetools-ui/organisms/checkout/provider/payment/types';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCurrencyMapperThresholdContext } from 'providers/currency-mapper-threshold';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import CreditCard from './components/creditCard';

interface Props {
    goToNextStep: () => void;
}

const Payment: React.FC<Props> = ({ goToNextStep }) => {
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { formatMessage: formatFieldErrorMessage } = useFormat({ name: 'field-errors' });

    const {
        paymentData,
        setPaymentData,
        selectedType,
        setSelectedType,
        availablePaymentMethods,
        availableInstalmentOptions,
        validatePaymentStep
    } = useCheckoutPaymentContext();

    const [poNumberFieldErrors, setPoNumberFieldErrors] = useState<FieldError>({});
    const { poNumberField, setPoNumber, validatePoNumber } = usePoNumber();
    const { invoiceEnabled, creditCardEnabled, instalmentRenewalMessage, unavailableInvoiceRenewalMessage, instalmentsEnabled } =
        useCheckoutSettings();
    const { currencyMapperThreshold } = useCurrencyMapperThresholdContext();

    const { data: cartData, transaction } = useCart();

    const [nextStepDisabled, setNextStepDisabled] = useState<boolean>(true);
    const [selectedInstalment, setSelectedInstalment] = useState<number>(0);
    const [membershipBelowThreshold, setMembershipBelowThreshold] = useState<boolean>(false);
    const [unavailableInvoiceRenewalMessageWithThreshold, setUnavailableInvoiceRenewalMessageWithThreshold] = useState<string>('');

    useEffect(() => {
        if (!selectedType) return;
        const paymentMethodNotSelected = ![PaymentMethodType.INVOICE, PaymentMethodType.CARD, PaymentMethodType.INSTALMENT].includes(
            selectedType
        );
        const instalmentNotSelected =
            selectedType === PaymentMethodType.INSTALMENT &&
            (selectedInstalment === 0 || !availableInstalmentOptions.includes(selectedInstalment));
        setNextStepDisabled(paymentMethodNotSelected || instalmentNotSelected);
    }, [selectedType, availableInstalmentOptions, selectedInstalment]);

    const trackAndGo = useCallback(() => {
        track({
            type: TagsActionType.ADD_PAYMENT_INFO,
            payload: {
                cart: cartData!,
                paymentMethod: selectedType === PaymentMethodType.INSTALMENT ? `${selectedType}-${selectedInstalment}` : selectedType
            }
        });
        goToNextStep();
    }, [goToNextStep, cartData, selectedType, selectedInstalment]);

    const submit = useCallback(() => {
        if (selectedType === PaymentMethodType.INVOICE) {
            const poNumberError = validatePoNumber();
            if (Object.keys(poNumberError).length) {
                setPoNumberFieldErrors(poNumberError);
                return;
            }
            setPaymentData({ ...paymentData, poNumber: poNumberField.value } as PaymentData);
        }
        setPoNumberFieldErrors({});
        trackAndGo();
    }, [trackAndGo, selectedType, poNumberField, paymentData, validatePoNumber, setPaymentData]);

    const handleCreditCardPaymentMethodSelection = useCallback(
        (type: PaymentMethodType) => {
            if (type !== selectedType) {
                setSelectedType(type);
                setPaymentData({ ...paymentData, type } as PaymentData);
                setSelectedInstalment(0);
            }
        },
        [selectedType, setPaymentData, paymentData, setSelectedType]
    );

    const handleInvoicePaymentMethodSelection = useCallback(() => {
        const type: PaymentMethodType = PaymentMethodType.INVOICE;
        if (type !== selectedType) {
            setSelectedType(type);
            setPaymentData({ type } as PaymentData);
            setSelectedInstalment(0);
        }
    }, [selectedType, setPaymentData, setSelectedType]);

    const handleInstalmentPaymentMethodSelection = useCallback(
        (instalment: number) => {
            const type: PaymentMethodType = PaymentMethodType.INSTALMENT;
            setSelectedType(type);
            setSelectedInstalment(instalment);
            setPaymentData({
                type,
                paymentInstalmentOption: instalment
            } as PaymentData);
        },
        [setPaymentData, setSelectedType]
    );

    useEffect(() => {
        const basketThreshold = currencyMapperThreshold[transaction.subtotal.currencyCode] ?? currencyMapperThreshold.default;
        const thresholdToDisplay = CurrencyHelpers.formatForCurrency(
            basketThreshold * 100,
            undefined,
            transaction.subtotal.currencyCode,
            transaction.subtotal.fractionDigits
        );

        setUnavailableInvoiceRenewalMessageWithThreshold(
            unavailableInvoiceRenewalMessage ? unavailableInvoiceRenewalMessage.replace('${threshold}', thresholdToDisplay.toString()) : ''
        );

        const membershipItems = cartData?.lineItems?.filter(li => li.productTypeKey === ProductTypeKey.MEMBERSHIP);

        const anyMembershipBelowThreshold =
            membershipItems
                ?.map(m => m.totalPrice)
                .some(tp => tp?.centAmount && tp?.centAmount < basketThreshold * Math.pow(10, transaction.subtotal.fractionDigits)) ??
            false;
        setMembershipBelowThreshold(anyMembershipBelowThreshold);
    }, [
        currencyMapperThreshold,
        transaction.subtotal.currencyCode,
        cartData?.lineItems,
        transaction.subtotal.fractionDigits,
        unavailableInvoiceRenewalMessage
    ]);

    const handleReviewOrderClick = useCallback(async () => {
        if (selectedType !== PaymentMethodType.CARD) {
            submit();
        } else {
            const isPaymentStepValid = await validatePaymentStep();

            if (isPaymentStepValid) {
                trackAndGo();
            }
        }
    }, [selectedType, submit, validatePaymentStep, trackAndGo]);

    return (
        <div className="lg:px-36 lg:pb-36 lg:pt-0">
            <div className="mt-12  bg-white lg:mt-0">
                {availablePaymentMethods.map(paymentMethod => {
                    switch (paymentMethod.name) {
                        case PaymentMethodType.CARD:
                            return (
                                creditCardEnabled && (
                                    <div key={paymentMethod.index} className="py-16 ">
                                        <div className="flex items-center justify-between lg:justify-start lg:gap-24">
                                            <div>
                                                <div className="flex items-center gap-16 leading-normal">
                                                    <Radio
                                                        onChange={() => handleCreditCardPaymentMethodSelection(PaymentMethodType.CARD)}
                                                        name="checkout-shipping-method"
                                                        label={formatCheckoutMessage({
                                                            id: 'creditDebitCard',
                                                            defaultMessage: 'Credit or debit card'
                                                        })}
                                                        id="creditCard"
                                                        checked={selectedType === PaymentMethodType.CARD}
                                                    />
                                                </div>
                                            </div>
                                            <div className="max-w-70 pb-16 lg:max-w-full">
                                                <Image
                                                    src={'/images/credit-debit-card.png'}
                                                    className="h-20 w-full lg:h-24"
                                                    alt="credit-debit-card"
                                                />
                                            </div>
                                        </div>
                                        <div className={selectedType !== PaymentMethodType.CARD ? 'hidden' : 'block'}>
                                            <CreditCard />
                                        </div>
                                    </div>
                                )
                            );
                        case PaymentMethodType.INVOICE:
                            return (
                                invoiceEnabled && (
                                    <div key={paymentMethod.index} className="py-16 ">
                                        <div className="flex items-center justify-between lg:justify-start lg:gap-24">
                                            <div>
                                                <div className="flex items-center gap-16 leading-normal">
                                                    <Radio
                                                        onChange={() => handleInvoicePaymentMethodSelection()}
                                                        name="checkout-shipping-method"
                                                        label={formatCheckoutMessage({ id: 'invoice', defaultMessage: 'Invoice' })}
                                                        id="invoice"
                                                        checked={selectedType === PaymentMethodType.INVOICE}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {selectedType == PaymentMethodType.INVOICE && (
                                            <div className="my-16 md:max-w-[436px]">
                                                <Input
                                                    type="text"
                                                    label="Purchase order number"
                                                    value={poNumberField.value}
                                                    onChange={e => setPoNumber(e.target.value)}
                                                    required={poNumberField.validation.required}
                                                />
                                                {poNumberFieldErrors.showError && poNumberFieldErrors.message && (
                                                    <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                                                        {formatFieldErrorMessage({
                                                            id: poNumberFieldErrors.message,
                                                            defaultMessage: 'Error in Purchase Order Number'
                                                        })}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                        {membershipBelowThreshold && selectedType == PaymentMethodType.INVOICE && (
                                            <div className="mb-16">{unavailableInvoiceRenewalMessageWithThreshold}</div>
                                        )}
                                    </div>
                                )
                            );
                        default:
                            return;
                    }
                })}
            </div>

            {instalmentsEnabled &&
                availableInstalmentOptions?.map(instalment => {
                    return (
                        <div key={instalment} className="py-16 ">
                            <div className="flex  items-center justify-between lg:justify-start lg:gap-24">
                                <div>
                                    <div className="flex items-center gap-16 leading-normal">
                                        <Radio
                                            onChange={() => handleInstalmentPaymentMethodSelection(instalment)}
                                            name="checkout-shipping-method"
                                            label={
                                                formatCheckoutMessage({
                                                    id: 'instalment.options.text',
                                                    defaultMessage: 'Credit or debit card {count} installments',
                                                    values: { count: instalment }
                                                }) +
                                                ' ' +
                                                CurrencyHelpers.formatForCurrency(
                                                    {
                                                        centAmount: transaction.total.centAmount / instalment,
                                                        currencyCode: transaction.total.currencyCode,
                                                        fractionDigits: transaction.total.fractionDigits
                                                    },
                                                    transaction.total.currencyCode
                                                )
                                            }
                                            id={'instalment' + instalment}
                                            checked={selectedInstalment === instalment && selectedType === PaymentMethodType.INSTALMENT}
                                        />
                                    </div>
                                </div>
                                <div className=" max-w-70 pb-36 lg:max-w-full lg:pb-16">
                                    <Image src={'/images/credit-debit-card.png'} className="h-20 w-full lg:h-24" alt="credit-debit-card" />
                                </div>
                            </div>
                            {availableInstalmentOptions.length > 0 &&
                                instalmentRenewalMessage &&
                                selectedType === PaymentMethodType.INSTALMENT &&
                                selectedInstalment === instalment &&
                                cartData?.lineItems?.some(li => li.productTypeKey === ProductTypeKey.MEMBERSHIP) && (
                                    <div className="pb-16 md:pl-24">{instalmentRenewalMessage}</div>
                                )}
                        </div>
                    );
                })}

            <div className="mt-24">
                <Button
                    variant="primary"
                    className="w-full min-w-200 md:text-16 lg:w-fit lg:px-36"
                    type="submit"
                    onClick={handleReviewOrderClick}
                    disabled={nextStepDisabled}
                    data-cy="review-order"
                    id="pay-button"
                >
                    {formatCheckoutMessage({ id: 'review.order', defaultMessage: 'Review order' })}
                </Button>
            </div>
        </div>
    );
};

export default Payment;
